export const initCookiePolicy = () => {
  const sec = document.getElementById('ot-sdk-cookie-policy')
  // console.log("sec",sec);
  if (sec && window.OneTrust) {
    // console.log('initCookiePolicy exec');
    window.OneTrust.initializeCookiePolicyHtml()

    // Aggiunge il listener al pulsante "Personalizza" cookie
    setTimeout(() => {
      const toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings'
      )
      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function(event) {
          event.stopImmediatePropagation()
          window.OneTrust.ToggleInfoDisplay()
        }
      }
    }, 1000)
  }
}
