// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/app/home/ariadne/gatsby-ovul/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-servizio-js": () => import("/app/home/ariadne/gatsby-ovul/src/templates/servizio.js" /* webpackChunkName: "component---src-templates-servizio-js" */),
  "component---src-templates-persona-js": () => import("/app/home/ariadne/gatsby-ovul/src/templates/persona.js" /* webpackChunkName: "component---src-templates-persona-js" */),
  "component---src-templates-news-js": () => import("/app/home/ariadne/gatsby-ovul/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-approfondimento-js": () => import("/app/home/ariadne/gatsby-ovul/src/templates/approfondimento.js" /* webpackChunkName: "component---src-templates-approfondimento-js" */),
  "component---src-templates-diagnostica-js": () => import("/app/home/ariadne/gatsby-ovul/src/templates/diagnostica.js" /* webpackChunkName: "component---src-templates-diagnostica-js" */),
  "component---src-templates-avvisi-js": () => import("/app/home/ariadne/gatsby-ovul/src/templates/avvisi.js" /* webpackChunkName: "component---src-templates-avvisi-js" */),
  "component---src-templates-news-ed-eventi-js": () => import("/app/home/ariadne/gatsby-ovul/src/templates/news-ed-eventi.js" /* webpackChunkName: "component---src-templates-news-ed-eventi-js" */),
  "component---src-pages-404-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accettazione-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/accettazione.js" /* webpackChunkName: "component---src-pages-accettazione-js" */),
  "component---src-pages-area-enti-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/area-enti.js" /* webpackChunkName: "component---src-pages-area-enti-js" */),
  "component---src-pages-area-studenti-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/area-studenti.js" /* webpackChunkName: "component---src-pages-area-studenti-js" */),
  "component---src-pages-area-veterinari-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/area-veterinari.js" /* webpackChunkName: "component---src-pages-area-veterinari-js" */),
  "component---src-pages-cavalli-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/cavalli.js" /* webpackChunkName: "component---src-pages-cavalli-js" */),
  "component---src-pages-collaborare-con-noi-studi-di-ricerca-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/collaborare-con-noi-studi-di-ricerca.js" /* webpackChunkName: "component---src-pages-collaborare-con-noi-studi-di-ricerca-js" */),
  "component---src-pages-dove-siamo-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/dove-siamo.js" /* webpackChunkName: "component---src-pages-dove-siamo-js" */),
  "component---src-pages-emergenza-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/emergenza.js" /* webpackChunkName: "component---src-pages-emergenza-js" */),
  "component---src-pages-esotici-e-non-convenzionali-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/esotici-e-non-convenzionali.js" /* webpackChunkName: "component---src-pages-esotici-e-non-convenzionali-js" */),
  "component---src-pages-index-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opportunita-lavorative-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/opportunita-lavorative.js" /* webpackChunkName: "component---src-pages-opportunita-lavorative-js" */),
  "component---src-pages-ospedale-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/ospedale.js" /* webpackChunkName: "component---src-pages-ospedale-js" */),
  "component---src-pages-piccoli-animali-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/piccoli-animali.js" /* webpackChunkName: "component---src-pages-piccoli-animali-js" */),
  "component---src-pages-prenota-c-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/prenota-c.js" /* webpackChunkName: "component---src-pages-prenota-c-js" */),
  "component---src-pages-prenota-p-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/prenota-p.js" /* webpackChunkName: "component---src-pages-prenota-p-js" */),
  "component---src-pages-prenota-r-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/prenota-r.js" /* webpackChunkName: "component---src-pages-prenota-r-js" */),
  "component---src-pages-prenota-visita-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/prenota-visita.js" /* webpackChunkName: "component---src-pages-prenota-visita-js" */),
  "component---src-pages-privacy-e-cookie-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/privacy-e-cookie.js" /* webpackChunkName: "component---src-pages-privacy-e-cookie-js" */),
  "component---src-pages-pronto-soccorso-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/pronto-soccorso.js" /* webpackChunkName: "component---src-pages-pronto-soccorso-js" */),
  "component---src-pages-reparti-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/reparti.js" /* webpackChunkName: "component---src-pages-reparti-js" */),
  "component---src-pages-ruminanti-e-suini-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/ruminanti-e-suini.js" /* webpackChunkName: "component---src-pages-ruminanti-e-suini-js" */),
  "component---src-pages-segreteria-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/segreteria.js" /* webpackChunkName: "component---src-pages-segreteria-js" */),
  "component---src-pages-staff-js": () => import("/app/home/ariadne/gatsby-ovul/src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/app/home/ariadne/gatsby-ovul/.cache/data.json")

